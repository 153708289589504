
import availableComponents from '~/config/homePageComponents'

export default {
  name: 'HomePageBuilder',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sortChildrenByPosition () {
      try {
        return [...this.component.children].sort(({ position: a }, { position: b }) => a - b)
      } catch {
        return this.component.children
      }
    },
  },

  methods: {
    getComponent (component) {
      if (typeof component === 'function' || typeof component === 'object') {
        return component
      }

      const componentFound = availableComponents[component]

      if (!componentFound) {
        // throw new Error(`You have not specified following component - ${component}`)
        // todo: remove console.log and uncomment error before prod
        console.log(`You have not specified following component - ${component}`)
      }

      return componentFound
    },
  },
}
