import { render, staticRenderFns } from "./index.vue?vue&type=template&id=abe43b3a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=abe43b3a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abe43b3a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomePageBuilder: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/HomePage/Builder/HomePageBuilder.vue').default})
