
import { createNamespacedHelpers } from 'vuex'
import title from '~/config/title'
import descriptions from '~/config/descriptions'

const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'PageIndex',
  layout: 'medistore',

  async asyncData ({ app, store }) {
    const homePageId = store.state.storeConfig?.storeConfig?.homePageUiId
    let components = []

    await app.$graphql({
      requestPayload: {
        query: (await import('~/graphql/homePage/getPageUiContent.graphql')).default,
        fetchPolicy: process.server ? 'no-cache' : 'cache-first',
        useGETForQueries: true,
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
        variables: {
          identifier: homePageId,
        },
      },
      successHandler: ({ data: { getPageUiContent } }) => {
        components = getPageUiContent.uiConfig.components.sort(({ position: posA }, { position: posB }) => posA - posB)
      },
      requestId: 'PageUiWrapper',
    })

    store.dispatch('breadcrumbs/setBreadcrumbs', {
      breadcrumbs: [],
      name: app.i18n.t('Homepage'),
    })

    return {
      components,
    }
  },

  head () {
    return {
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: this.$i18n.locale === 'pl' ? 'all' : 'none',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${this.$config.appUrl}/logo/default-logo-og.svg`,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title[this.$config.storeCode]?.[this.$i18n.locale] ?? '',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: descriptions[this.$config.storeCode]?.[this.$i18n.locale] ?? '',
        },
      ],
    }
  },

  computed: {
    ...mapStateConfig(['storeConfig']),
  },

  mounted () {
    this.$gtm.push({ event: 'page_view' })
  },

  jsonld () {
    const { appUrl } = this.$config
    const { storeInformationName } = this.storeConfig

    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          name: storeInformationName,
          url: appUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${appUrl}?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        },
      ],
    }
  },

}
